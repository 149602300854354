import React, { Suspense, lazy } from 'react';
import AdfLogs from '../landing/AdfLogs';
import PreprocessingLogs from '../landing/PreprocessingLogs';

const Dashboard = lazy(() => {
    return import('../dashboard/Dashboard');
});
const AllLoss = lazy(() => {
    return import('../all-loss/AllLoss');
});
const Renamer = lazy(() => {
    return import('../landing/Renamer');
});
const FileUpload = lazy(() => {
    return import('../landing/FileUpload');
});
const Logs = lazy(() => {
    return import('../landing/Logs');
});
const Account = lazy(() => {
    return import('../master-data/Account');
});
const Carrier = lazy(() => {
    return import('../master-data/Carrier');
});
const LineOfBusiness = lazy(() => {
    return import('../master-data/LineOfBusiness');
});
const LookupInsured = lazy(() => {
    return import('../master-data/LookupInsured');
});
const PolicySchedule = lazy(() => {
    return import('../master-data/PolicySchedule');
});
const Setting = lazy(() => {
    return import('../setting/Setting');
});
const Preprocessing = lazy(() => {
    return import('../setting/Preprocessing');
});
const PdfTransform = lazy(() => {
    return import('../setting/PdfTransform');
});
const ExcelTransform = lazy(() => {
    return import('../setting/ExcelTransform');
});

const LazyComponents = {
    Dashboard: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <Dashboard />
            </Suspense>
        );
    },
    AllLoss: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <AllLoss />
            </Suspense>
        );
    },
    Renamer: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <Renamer />
            </Suspense>
        );
    },
    FileUpload: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <FileUpload />
            </Suspense>
        );
    },
    Logs: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <Logs />
            </Suspense>
        );
    },
    PreprocessingLogs: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <PreprocessingLogs />
            </Suspense>
        );
    },
    AdfLogs: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <AdfLogs />
            </Suspense>
        );
    },
    Account: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <Account />
            </Suspense>
        );
    },
    Carrier: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <Carrier />
            </Suspense>
        );
    },
    LineOfBusiness: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <LineOfBusiness />
            </Suspense>
        );
    },
    LookupInsured: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <LookupInsured />
            </Suspense>
        );
    },
    PolicySchedule: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <PolicySchedule />
            </Suspense>
        );
    },
    Setting: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <Setting />
            </Suspense>
        );
    },
    Preprocessing: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <Preprocessing />
            </Suspense>
        );
    },
    PdfTransform: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <PdfTransform />
            </Suspense>
        );
    },
    ExcelTransform: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <ExcelTransform />
            </Suspense>
        );
    },
};

export default LazyComponents;
