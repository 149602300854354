import { Route, Routes, useLocation } from 'react-router-dom';
import { landingTabItem, masterDataTabItem, settingsTabItem } from '../tab/TabItems';
import LazyComponents from './LazyComponents';
import NavigationTab from '../tab/NavigationTab';
import Postprocessing from '../setting/Postprocessing';
import React from 'react';
import ZealDocLayoutContainer from './ZealDocLayoutContainer';
import route from './routes.json';

function ZealDocRoutes() {
    const currentLocation = useLocation();
    // Const locationState = currentLocation.state as locationStateType;
    // Const previousLocation = {
    //   ...locationState?.backgroundLocation || currentLocation,
    //   State: currentLocation.state,
    // } as locationType;

    return (
        <Routes location={currentLocation}>
            <Route
                index={true}
                caseSensitive={true}
                element={
                    <ZealDocLayoutContainer>
                        <LazyComponents.Dashboard />
                    </ZealDocLayoutContainer>
                }
            />
            <Route
                path={route['dashboard']}
                caseSensitive={true}
                element={
                    <ZealDocLayoutContainer>
                        <LazyComponents.Dashboard />
                    </ZealDocLayoutContainer>
                }
            />
            <Route
                path={route['allLoss']}
                caseSensitive={true}
                element={
                    <ZealDocLayoutContainer>
                        <LazyComponents.AllLoss />
                    </ZealDocLayoutContainer>
                }
            />
            <Route
                path={route['landing']['index']}
                caseSensitive={true}
                element={
                    <ZealDocLayoutContainer>
                        <NavigationTab tab="landing" tabItems={landingTabItem} />
                    </ZealDocLayoutContainer>
                }
            >
                <Route
                    index={true}
                    caseSensitive={true}
                    element={<LazyComponents.Renamer />}
                />
                <Route
                    path={`${route['landing']['renamer']}`}
                    caseSensitive={true}
                    element={<LazyComponents.Renamer />}
                />
                <Route
                    path={`${route['landing']['fileUpload']}`}
                    caseSensitive={true}
                    element={<LazyComponents.FileUpload />}
                />
                <Route
                    path={`${route['landing']['logs']}`}
                    caseSensitive={true}
                    element={<LazyComponents.Logs />}
                >
                    <Route
                        path={`${route['landing']['preprocessingLogs']}`}
                        caseSensitive={true}
                        element={<LazyComponents.PreprocessingLogs />}
                    />
                    <Route
                        path={`${route['landing']['adfLogs']}`}
                        caseSensitive={true}
                        element={<LazyComponents.AdfLogs />}
                    />
                </Route>
            </Route>
            <Route
                path={route['master-data']['index']}
                caseSensitive={true}
                element={
                    <ZealDocLayoutContainer>
                        <NavigationTab tab="masterData" tabItems={masterDataTabItem} />
                    </ZealDocLayoutContainer>
                }
            >
                <Route
                    index={true}
                    caseSensitive={true}
                    element={<LazyComponents.Account />}
                />
                <Route
                    path={`${route['master-data']['account']}`}
                    caseSensitive={true}
                    element={<LazyComponents.Account />}
                />
                <Route
                    path={`${route['master-data']['carrier']}`}
                    caseSensitive={true}
                    element={<LazyComponents.Carrier />}
                />
                <Route
                    path={`${route['master-data']['lob']}`}
                    caseSensitive={true}
                    element={<LazyComponents.LineOfBusiness />}
                />
                <Route
                    path={`${route['master-data']['lkpInsured']}`}
                    caseSensitive={true}
                    element={<LazyComponents.LookupInsured />}
                />
                <Route
                    path={`${route['master-data']['policySchedule']}`}
                    caseSensitive={true}
                    element={<LazyComponents.PolicySchedule />}
                />
            </Route>
            <Route
                path={route['setting']['index']}
                caseSensitive={true}
                element={
                    <ZealDocLayoutContainer>
                        <NavigationTab tab="settings" tabItems={settingsTabItem} />
                    </ZealDocLayoutContainer>
                }
            >
                <Route
                    index={true}
                    caseSensitive={true}
                    element={<LazyComponents.Preprocessing />}
                />
                <Route
                    path={`${route['setting']['preprocessing']}`}
                    caseSensitive={true}
                    element={<LazyComponents.Preprocessing />}
                />
                <Route
                    path={`${route['setting']['postprocessing']}`}
                    caseSensitive={true}
                    element={<Postprocessing />}
                >
                    <Route
                        path={`${route['setting']['pdfTransform']}`}
                        caseSensitive={true}
                        element={<LazyComponents.PdfTransform />}
                    />
                    <Route
                        path={`${route['setting']['excelTransform']}`}
                        caseSensitive={true}
                        element={<LazyComponents.ExcelTransform />}
                    />
                </Route>
            </Route>
        </Routes>
    );
}

export default ZealDocRoutes;
