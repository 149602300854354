import React, { useCallback, useEffect, useState } from 'react';
import { AZURE_API_ENDPOINT } from '../constants/protectedResources';
import { MASTER_DATA_PAGE_LIMIT } from '../constants/constants';
import Search from '../components/Search';
import ZealDocPagination from '../components/Pagination';
import { toast } from 'react-toastify';
import useAccessToken from '../hooks/useAccessToken';

interface adfLogsType {
    operationName: string;
    userProperties: string;
    client: string;
    start: string;
    activityName: string;
    activityType: string;
    end: string;
    failureType: string;
    pipelineName: string;
    input: string;
    output: string;
    status: string;
    errorCode: string;
    errorMessage: string;
    logGeneratedOn: Date;
    fileName: string;
    uniqueIdentifier: string;
}

function AdfLogs() {
    const { accessToken } = useAccessToken();

    const [adfLogs, setAdfLogs] = useState<adfLogsType[]>([]);

    const [totalLogs, setTotalLogs] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [searchTerm, setSearchTerm] = useState('');

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getAdfLogsApi = useCallback(
        (pageNumber: number = 1, search: string = '') => {
            if (accessToken === '') {
                return;
            }
            const header = new Headers();
            header.append('Authorization', `Bearer ${accessToken}`);

            let apiUrl = `${AZURE_API_ENDPOINT}v1/log/adf?page_size=${MASTER_DATA_PAGE_LIMIT}&page_number=${pageNumber}`;
            if (search) {
                apiUrl += `&search=${encodeURIComponent(search)}`;
            }
            // Start loading before making the request
            setIsLoading(true);
            fetch(apiUrl, { method: 'GET', headers: header })
                .then((response) => {
                    if (response.status === 200) {
                        return response.text();
                    }
                    throw new Error(response.statusText);
                })
                .then((result) => {
                    const parsedEntities = JSON.parse(result);
                    const adfLogData: adfLogsType[] = parsedEntities['data'].map(
                        (entity: any) => {
                            const userProperties = JSON.parse(
                                entity['user_properties'] || '{}',
                            );
                            return {
                                operationName: entity['operation_name'],
                                userProperties: entity['user_properties'],
                                start: entity['start'],
                                activityName: entity['activity_name'],
                                activityType: entity['activity_type'],
                                end: entity['end'],
                                failureType: entity['failure_type'],
                                pipelineName: entity['pipeline_name'],
                                input: entity['input'],
                                output: entity['output'],
                                status: entity['status'],
                                errorCode: entity['error_code'],
                                errorMessage: entity['error_message'],
                                logGeneratedOn: new Date(entity['time_generated']),
                                fileName: userProperties.FileName || 'N/A',
                                uniqueIdentifier:
                                    userProperties.UniqueIdentifier || 'N/A',
                            } as adfLogsType;
                        },
                    );
                    setAdfLogs(adfLogData);
                    setTotalLogs(parsedEntities['total']);
                })
                .catch((error: any) => {
                    toast.error(error.toString());
                })
                .finally(() => {
                    setIsLoading(false);
                    // Stop loading after API call completes
                });
        },
        [accessToken],
    );

    useEffect(() => {
        getAdfLogsApi(currentPage, searchTerm);
    }, [getAdfLogsApi, currentPage, searchTerm]);

    const getSearchInput = (input: string) => {
        setSearchTerm(input);
        setCurrentPage(1);
        // Reset to the first page for a new search
    };

    const clearSearchInput = () => {
        setSearchTerm('');
        setCurrentPage(1);
        // Reset to the first page after clearing search input
    };
    return (
        <div className="adfLog-container content-container">
            <div style={{ width: '1480px' }}>
                <Search
                    placeholder="Search by account name"
                    searchTerm={searchTerm}
                    getSearchInput={getSearchInput}
                    clearSearchInput={clearSearchInput}
                />
            </div>
            <div className="scrollable-container gz-scroll" style={{ width: '1480px' }}>
                {isLoading ? (
                    // Spinner -Show loading indicator while fetching data
                    <div className="inline-spinner"></div>
                ) : (
                    <table className="file-line-item-container">
                        <thead>
                            <tr className="file-header">
                                <td className="file-header-item">FileName</td>
                                <td className="file-header-item">UniqueIdentifier</td>
                                <td className="file-header-item">Activity Name</td>
                                <td className="file-header-item">Status</td>
                                <td className="file-header-item">Operation Name</td>
                                <td className="file-header-item">Log Generated On</td>
                                <td className="file-header-item">Failure Type</td>
                                <td className="file-header-item">Error Code</td>
                                <td className="file-header-item">Error Message</td>
                                <td className="file-header-item">Pipeline Name</td>
                                <td className="file-header-item">Input</td>
                                <td className="file-header-item">Output</td>
                                <td className="file-header-item">Start</td>
                                {/*<td className="file-header-item">End</td> */}
                            </tr>
                        </thead>
                        <tbody>
                            {adfLogs.map((adfLog, index) => {
                                return (
                                    <tr
                                        key={`${adfLog.fileName}-id-${index}`}
                                        className="file-body"
                                    >
                                        <td
                                            className="file-body-item"
                                            style={{
                                                maxWidth: '2300px',
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            {adfLog.fileName}
                                        </td>
                                        <td className="file-body-item">
                                            {adfLog.uniqueIdentifier}
                                        </td>
                                        <td className="file-body-item">
                                            {adfLog.activityName}
                                        </td>
                                        <td className="file-body-item">
                                            {adfLog.status}
                                        </td>
                                        <td className="file-body-item">
                                            {adfLog.operationName}
                                        </td>
                                        <td className="file-body-item">
                                            {adfLog.logGeneratedOn.toLocaleDateString()}
                                        </td>
                                        <td className="file-body-item">
                                            {adfLog.failureType}
                                        </td>

                                        <td className="file-body-item">
                                            {adfLog.errorCode}
                                        </td>

                                        <td className="file-body-item">
                                            {adfLog.errorMessage}
                                        </td>
                                        <td className="file-body-item">
                                            {adfLog.pipelineName}
                                        </td>
                                        <td
                                            className="file-body-item"
                                            style={{
                                                maxWidth: '300px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}
                                            title={adfLog.input}
                                        >
                                            {adfLog.input}
                                        </td>
                                        <td
                                            className="file-body-item"
                                            style={{
                                                maxWidth: '300px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}
                                            title={adfLog.output}
                                        >
                                            {adfLog.input}
                                        </td>

                                        <td className="file-body-item">{adfLog.start}</td>
                                        {/*<td className="file-body-item">{adfLog.end}</td>*/}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            <ZealDocPagination
                totalRecords={totalLogs}
                recordLimitPerPage={MASTER_DATA_PAGE_LIMIT}
                siblingCount={1}
                currentPage={currentPage}
                tabStyle="project"
                onPageChange={(page) => {
                    return setCurrentPage(page);
                }}
            />
        </div>
    );
}

export default AdfLogs;
