export const ROOT_FOLDER = 'mango';
export const ROOT_FOLDER_CLIENT = 'client';
export const MAX_UPLOAD_LIMIT = 20;
export const TERM_YEAR = 20;
export const MASTER_DATA_PAGE_LIMIT = 50;
export const ZIP_DOWNLOAD_FILE_NAME_PREFIX = 'Renamed Loss Runs';
export const MAX_UPLAOD_ERROR = `Maximum of ${MAX_UPLOAD_LIMIT} files are allowed to upload at a time`;
export const SUPPORTED_FILE_FORAMTS = ['.pdf', '.xlsx', '.xls', '.csv'];
export const SUPPORTED_FILE_TYPES = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'text/csv',
    'application/vnd.ms-excel',
];
export const HELP_TEXT = `Allowed file formats: ${SUPPORTED_FILE_FORAMTS.join(',')}. Maximum file limit: ${MAX_UPLOAD_LIMIT}.`;
export const FILE_NOT_SUPPORTED_ERROR = `Allowed file formats: ${SUPPORTED_FILE_FORAMTS.join(', ')}`;

/* Radio button values */
export const RADIO_BUTTON_VALUES: { id: 'dashboard' | 'expiration'; label: string }[] = [
    { id: 'dashboard', label: 'Dashboard' },
    { id: 'expiration', label: 'Expiration' },
];
