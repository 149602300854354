import { useMemo } from 'react';

export const DOTS = '...';

const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, index) => {
        return index + start;
    });
};

export const usePagination = (
    totalRecords: number,
    recordLimitPerPage: number,
    siblingCount: number,
    currentPage: number,
) => {
    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalRecords / recordLimitPerPage);
        /* SiblingCount + firstPage + lastPage + currentPage + 2dots */
        const totalPageNumbers = siblingCount + 5;

        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        const leftSibling = Math.max(currentPage - siblingCount, 1);
        const rightSibling = Math.min(currentPage + siblingCount, totalPageCount);

        const shouldShowLeftDots = leftSibling > 2;
        const shouldShowRightDots = rightSibling < totalPageCount - 2;

        const firstPage = 1;
        const lastPage = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3 + 2 * siblingCount;
            const leftRange = range(1, leftItemCount);
            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * siblingCount;
            const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
            return [firstPage, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSibling, rightSibling);
            return [firstPage, DOTS, ...middleRange, DOTS, lastPage];
        }
        return null;
    }, [totalRecords, recordLimitPerPage, siblingCount, currentPage]);

    return paginationRange;
};
